import React, { useEffect, useContext } from "react"
import styles from "./BsCubeDesktop.module.css"
import Button from "../../Common/Button"
import { useSpring, animated, config } from "react-spring"
import { MobileCtx } from "../../Context/MobileCtx"

const BsCubeBrowser = ({ onOpenHandler, initAnim }) => {
	const ctx = useContext(MobileCtx)
	const [spring, api] = useSpring(() => ({
		opacity: 1,
		translateX: "0rem",
		translateY: "0rem",
		config: config.slow,
	}))

	useEffect(() => {
		if (initAnim) {
			api.start({
				opacity: 0,
				translateX: ctx.mobile ? "0rem" : "-20rem",
				translateY: ctx.mobile ? "20rem" : "0rem",
			})
		} else {
			api.start({ opacity: 1, translateX: "0", translateY: "0rem" })
		}
		return () => {}
	}, [initAnim])

	return (
		<animated.div className={styles.bsCubeDesktop} style={spring}>
			<div className={styles.container}>
				<div className={styles.text}>
					<h3>BROWSERCUBE DESKTOP</h3>
					<p>
						Still want to login your accounts and make some manual tasks? We've got you covered. You can
						start a sessions and access your accounts with our custom desktop application that offers a
						secure access no need for your own proxies and multi-login extensions.
					</p>
				</div>
				<Button color="#F74C47" className={styles.button} onClick={onOpenHandler}></Button>
			</div>
		</animated.div>
	)
}

export default BsCubeBrowser
