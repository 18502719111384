import React from "react"
import FbasSlide from "./FbasSlide/FbasSlide"
import FbasModal from "./FbasModal/FbasModal"
import Modal from "../../Utilities/Modal"

const Fbas = () => {
	return <Modal Slide={FbasSlide} Modal={FbasModal} scrollTo={6072}></Modal>
}

export default Fbas
