import React, { useState, useEffect, useContext } from "react"
import { MobileCtx } from "../../Context/MobileCtx"
import { BsCubeModalImage } from "../../../assets/BrowserCube/BsCubeSVG"
import Button from "../../Common/Button"
import BsCubeCarousel from "./BsCubeCarousel"
import styles from "./BsCubeModal.module.css"

const BsCubeModal = ({ onCloseHandler }) => {
	const [imgOp, setImgOp] = useState(true)
	const ctx = useContext(MobileCtx)

	useEffect(() => {
		setTimeout(() => {
			setImgOp(false)
		}, 1300)

		return () => {}
	}, [])

	return (
		<>
			<div className={`${styles.bsCubeModal} slideBody`}>
				<div className={styles.container}>
					<h1>BrowserCube</h1>
				</div>
				<BsCubeModalImage
					style={{ opacity: imgOp ? 1 : 0, display: ctx.mobile ? "none" : "block" }}
					className={styles.imageFlip}
				></BsCubeModalImage>
				<Button
					onClick={onCloseHandler}
					color="#F74C47"
					text="CLOSE"
					className={styles.button}
				></Button>
				<BsCubeCarousel></BsCubeCarousel>
			</div>
		</>
	)
}

export default React.memo(BsCubeModal)
