import React from "react"
import styles from "./CheckBoxItem.module.css"
import { Check } from "../../assets/Stats/StatsSVG"

const CheckBoxItem = ({ children: text }) => {
	return (
		<span className={styles.checkBoxItem}>
			<span className={styles.check}>
				<Check></Check>
			</span>

			<h3>{text}</h3>
		</span>
	)
}

export default CheckBoxItem
