import styles from "./ServicesSVG.module.css"

export const AutomationsIcon = ({ className }) => {
	return (
		<div className={className}>
			<div className={styles.automationsIcon}>
				<svg
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={styles.gearOne}
				>
					<path
						d="M59.1167 37.2203L53.7537 34.2424C54.3009 31.4446 54.3063 28.5733 53.7696 25.7734L59.1438 22.8157C59.7619 22.4791 60.0405 21.7799 59.8401 21.128C58.4493 16.8306 56.0747 12.9414 52.968 9.7024C52.4901 9.20687 51.7089 9.08476 51.1034 9.42142L45.7293 12.3791C43.4769 10.517 40.8835 9.07651 38.075 8.13023L38.0861 2.20674C38.0874 1.53115 37.597 0.939081 36.9041 0.79301C32.281 -0.204929 27.5424 -0.165565 23.1424 0.767184C22.449 0.910653 21.9564 1.50087 21.9552 2.17647L21.944 8.11202C19.1445 9.05984 16.5458 10.4906 14.2739 12.3322L8.92347 9.35433C8.30659 9.01537 7.53764 9.12251 7.0578 9.6283C3.93899 12.8436 1.54986 16.7238 0.142926 21.028C-0.0725355 21.6791 0.216004 22.3794 0.832883 22.7183L6.1959 25.6962C5.64874 28.494 5.64336 31.3653 6.18 34.1652L0.805851 37.1229C0.187704 37.4596 -0.0908599 38.1588 0.109554 38.8106C1.50035 43.1081 3.8749 46.9972 6.9816 50.2362C7.45956 50.7317 8.24067 50.8538 8.84622 50.5172L14.2204 47.5595C16.4727 49.4216 19.0661 50.8621 21.8746 51.8084L21.8635 57.7439C21.8622 58.4195 22.3526 59.0116 23.0455 59.1577C27.6686 60.1556 32.4072 60.1162 36.8072 59.1835C37.5006 59.04 37.9932 58.4498 37.9944 57.7742L38.0056 51.8386C40.8051 50.8908 43.4038 49.4601 45.6757 47.6185L51.0387 50.5964C51.6556 50.9353 52.4246 50.8282 52.9044 50.3224C56.0232 47.1071 58.4123 43.2269 59.8193 38.9226C60.0096 38.2595 59.7336 37.5592 59.1167 37.2203V37.2203ZM29.9504 39.6146C24.3928 39.6041 19.8767 35.2646 19.8867 29.9443C19.8967 24.6241 24.429 20.3015 29.9866 20.3119C35.5443 20.3224 40.0603 24.6619 40.0504 29.9822C40.0404 35.3025 35.508 39.625 29.9504 39.6146Z"
						fill="white"
					/>
				</svg>
				<svg
					width="30"
					height="30"
					viewBox="0 0 30 29"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={styles.gearTwo}
				>
					<path
						d="M28.4263 11.8263L25.561 11.6547C25.2259 10.3635 24.6473 9.15085 23.8544 8.078L25.5237 5.74283C25.7165 5.47574 25.6925 5.12436 25.4761 4.88986C24.0199 3.35791 22.2312 2.19726 20.2651 1.45903C19.9632 1.34663 19.6089 1.45313 19.4215 1.71768L17.7522 4.05284C16.4252 3.72277 15.0395 3.63958 13.663 3.80846L12.4694 1.3068C12.3333 1.02148 12.0066 0.87086 11.6847 0.949388C9.53204 1.46356 7.54058 2.43876 5.87271 3.72243C5.60916 3.92324 5.52071 4.27193 5.65684 4.55725L6.85288 7.064C5.86338 8.03026 5.05627 9.15969 4.47019 10.3963L1.61019 10.2222C1.28133 10.204 0.978553 10.4047 0.878406 10.7152C0.212868 12.7028 -0.010274 14.8234 0.266808 16.9242C0.307602 17.2425 0.571011 17.4796 0.899871 17.4978L3.76519 17.6694C4.10032 18.9606 4.67889 20.1732 5.47174 21.2461L3.80245 23.5813C3.60973 23.8483 3.63363 24.1997 3.85006 24.4342C5.30627 25.9662 7.09495 27.1268 9.06106 27.8651C9.36298 27.9775 9.71727 27.871 9.90468 27.6064L11.574 25.2712C12.901 25.6013 14.2867 25.6845 15.6632 25.5156L16.8592 28.0224C16.9954 28.3077 17.3221 28.4583 17.6439 28.3798C19.7966 27.8656 21.788 26.8904 23.4559 25.6068C23.7195 25.4059 23.8079 25.0572 23.6718 24.7719L22.4757 22.2652C23.4652 21.2989 24.2723 20.1695 24.8584 18.9329L27.7237 19.1044C28.0526 19.1227 28.3554 18.9219 28.4555 18.6114C29.1211 16.6238 29.3442 14.5033 29.0671 12.4025C29.0133 12.0842 28.7552 11.8445 28.4263 11.8263V11.8263ZM16.604 18.7368C14.2569 19.8567 11.4734 18.9392 10.4014 16.6923C9.32931 14.4454 10.3673 11.7046 12.7144 10.5847C15.0616 9.46487 17.845 10.3823 18.9171 12.6292C19.9891 14.8761 18.9511 17.6169 16.604 18.7368Z"
						fill="white"
					/>
				</svg>
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={styles.gearThree}
				>
					<path
						d="M17.6267 11.3828L16.1006 10.5391C16.2546 9.74344 16.2546 8.92721 16.1006 8.13156L17.6267 7.2879C17.8023 7.19187 17.8811 6.99296 17.8238 6.80777C17.4261 5.58685 16.749 4.48255 15.8642 3.56343C15.728 3.42282 15.5059 3.38853 15.3339 3.48455L13.8078 4.32822C13.1665 3.80007 12.4285 3.39196 11.6296 3.12445V1.44055C11.6296 1.2485 11.4899 1.08045 11.2929 1.0393C9.97808 0.758079 8.63106 0.771797 7.38076 1.0393C7.18372 1.08045 7.044 1.2485 7.044 1.44055V3.12788C6.24868 3.39881 5.51069 3.80693 4.86583 4.33165L3.34327 3.48798C3.16772 3.39196 2.94919 3.42282 2.81305 3.56686C1.92817 4.48255 1.25108 5.58685 0.853419 6.81119C0.792516 6.99639 0.874914 7.1953 1.05046 7.29133L2.57661 8.13499C2.42256 8.93064 2.42256 9.74687 2.57661 10.5425L1.05046 11.3862C0.874914 11.4822 0.796099 11.6811 0.853419 11.8663C1.25108 13.0872 1.92817 14.1915 2.81305 15.1107C2.94919 15.2513 3.17131 15.2856 3.34327 15.1895L4.86942 14.3459C5.51069 14.874 6.24868 15.2821 7.04759 15.5496V17.237C7.04759 17.429 7.1873 17.5971 7.38434 17.6382C8.69912 17.9194 10.0461 17.9057 11.2964 17.6382C11.4935 17.5971 11.6332 17.429 11.6332 17.237L11.6332 15.5496C12.4285 15.2787 13.1665 14.8706 13.8114 14.3459L15.3375 15.1895C15.5131 15.2856 15.7316 15.2547 15.8677 15.1107C16.7526 14.195 17.4297 13.0907 17.8274 11.8663C17.8811 11.6777 17.8023 11.4788 17.6267 11.3828V11.3828ZM9.33681 12.0789C7.75692 12.0789 6.4708 10.8477 6.4708 9.33533C6.4708 7.82291 7.75692 6.59171 9.33681 6.59171C10.9167 6.59171 12.2028 7.82291 12.2028 9.33533C12.2028 10.8477 10.9167 12.0789 9.33681 12.0789Z"
						fill="white"
					/>
				</svg>
			</div>
		</div>
	)
}

export const MassLaunchIcon = ({ className }) => {
	return (
		<div className={className}>
			<div className={styles.massLaunchIcon}>
				<svg
					width="56"
					height="62"
					viewBox="0 0 56 62"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={styles.rocketBody}
				>
					<path
						d="M49 46.6254L56 54.5465V61.2766H0V54.5465L7 46.6254V26.3512C7 14.1867 15.764 3.83481 28 0C40.236 3.83481 49 14.1867 49 26.3512V46.6254ZM28 33.3363C29.8565 33.3363 31.637 32.6004 32.9497 31.2904C34.2625 29.9804 35 28.2038 35 26.3512C35 24.4986 34.2625 22.722 32.9497 21.412C31.637 20.1021 29.8565 19.3661 28 19.3661C26.1435 19.3661 24.363 20.1021 23.0503 21.412C21.7375 22.722 21 24.4986 21 26.3512C21 28.2038 21.7375 29.9804 23.0503 31.2904C24.363 32.6004 26.1435 33.3363 28 33.3363Z"
						fill="white"
					/>
				</svg>
				<svg
					width="25"
					height="13"
					viewBox="0 0 25 13"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={styles.rocketTail}
				>
					<path
						d="M0 0H24.514C22.1985 5.52632 17.7951 9.92034 12.257 12.2309C6.71888 9.92034 2.31547 5.52632 0 0Z"
						fill="white"
					/>
				</svg>
			</div>
		</div>
	)
}

export const StatsMasterIcon = ({ className }) => {
	return (
		<div className={className}>
			<svg
				width="91"
				height="81"
				viewBox="0 0 91 81"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={styles.statsIcon}
			>
				<path
					d="M91 9.05899C91 14.0621 86.8773 18.118 81.7918 18.118C76.7062 18.118 72.5835 14.0621 72.5835 9.05899C72.5835 4.05585 76.7062 0 81.7918 0C86.8773 0 91 4.05585 91 9.05899Z"
					fill="white"
				/>
				<path
					d="M66.6248 57.017C66.6248 62.0202 62.5021 66.076 57.4165 66.076C52.331 66.076 48.2083 62.0202 48.2083 57.017C48.2083 52.0139 52.331 47.9581 57.4165 47.9581C62.5021 47.9581 66.6248 52.0139 66.6248 57.017Z"
					fill="white"
				/>
				<path
					d="M42.7915 29.8415C42.7915 34.8446 38.6688 38.9005 33.5832 38.9005C28.4976 38.9005 24.375 34.8446 24.375 29.8415C24.375 24.8383 28.4976 20.7825 33.5832 20.7825C38.6688 20.7825 42.7915 24.8383 42.7915 29.8415Z"
					fill="white"
				/>
				<path
					d="M18.4165 71.941C18.4165 76.9441 14.2938 81 9.20825 81C4.12267 81 0 76.9441 0 71.941C0 66.9379 4.12267 62.882 9.20825 62.882C14.2938 62.882 18.4165 66.9379 18.4165 71.941Z"
					fill="white"
				/>
				<path
					d="M75.6803 14.3891L82.0308 17.6866L63.2256 52.7387L56.8751 49.4413L75.6803 14.3891Z"
					fill="white"
				/>
				<path
					d="M28.0145 30.9085L34.365 34.2059L15.5597 69.2581L9.20929 65.9607L28.0145 30.9085Z"
					fill="white"
				/>
				<path
					d="M56.1874 50.6774L50.6491 55.1739L34.1595 35.5164L39.6978 31.02L56.1874 50.6774Z"
					fill="white"
				/>
			</svg>
		</div>
	)
}
