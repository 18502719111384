import React from "react"
import Modal from "../../Utilities/Modal"
import BsCubeSlide from "./BsCubeSlide/BsCubeSlide"
import BsCubeModal from "./BsCubeModal/BsCubeModal"

const BsCube = ({ initAnim }) => {
	return <Modal Slide={BsCubeSlide} Modal={BsCubeModal} scrollTo={7576} initAnim={initAnim}></Modal>
}

export default BsCube
