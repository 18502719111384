import React, { useMemo } from "react"
import * as THREE from "three"
import logo from "../../assets/Common/blackscalemedia.svg"
import { useLoader } from "@react-three/fiber"

const Logo = ({ opacity, textRef, size, position }) => {
	const imgLoader = useLoader(THREE.ImageLoader, logo)
	const scale = window.innerHeight > window.innerWidth ? window.innerHeight : window.innerWidth

	const canvasContext = useMemo(() => {
		const canvas = document.createElement("canvas")
		canvas.width = canvas.height = 2048
		const context = canvas.getContext("2d")
		context.drawImage(imgLoader, 725, 900)
		return canvas
	}, [])

	return (
		<>
			<sprite scale={[scale * size, scale * size, 1]} position={position} ref={textRef}>
				<spriteMaterial opacity={opacity}>
					<canvasTexture
						attach="map"
						image={canvasContext}
						premultiplyAlpha
						onUpdate={(s) => (s.needsUpdate = true)}
					/>
				</spriteMaterial>
			</sprite>
		</>
	)
}

export default React.memo(Logo)
