export const Check = ({ className }) => {
	return (
		<svg width="36" height="29" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3 17L12.5 26.5L33 3"
				stroke="#7BD19F"
				strokeWidth="5"
				strokeLinecap="round"
				strokeLinejoin="round"
				className={className}
			/>
		</svg>
	)
}
