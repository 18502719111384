import React from "react"
import ClientsItem from "./ClientsItem"
import client1 from "../../assets/Clients/client1.png"
import client2 from "../../assets/Clients/client2.png"
import client3 from "../../assets/Clients/client3.png"
import client4 from "../../assets/Clients/client4.png"
import styles from "./Clients.module.css"

const Clients = ({ initAnim }) => {
	return (
		<div className={styles.Clients}>
			<ClientsItem
				logo={client1}
				name="JOHN CASTO - ECOMWOLF"
				business="Facebook ECOM Guru"
				top={true}
				left={true}
				text="I've been dealing with Facebook account bans and issues for years now - never has there been a
				better software to make your life easier at scale to run white or black hat ads across multiple
				accounts until now. Thanks FBAS for creating a truly unique software the marketplace absolutely
				needs - can't recommend enough"
				initAnim={initAnim}
				vip={true}
			></ClientsItem>

			<ClientsItem
				logo={client2}
				name="DEBOJEET"
				business="Solo Media Buyer"
				top={true}
				left={false}
				text="This service is super recommended for those who hate being a Jerry every other week. How are you
				supposed to keep your business steady, while you have to figure out new hacks to avoid Tom every
				other week?!"
				initAnim={initAnim}
			></ClientsItem>

			<ClientsItem
				logo={client3}
				name="Jeroen - Adpeako"
				business="Media Buyers Team"
				top={false}
				left={true}
				text="I always knew that the infrastructure dirty work had stolen tons of precious time from me. But
				since i've started using FBAS, I've found out that it wasn't just a time issue - it was a HUGE
				FOCUS KILLER!"
				initAnim={initAnim}
			></ClientsItem>

			<ClientsItem
				logo={client4}
				name="Bogdan"
				business="Media Agency"
				top={false}
				left={false}
				text="Finally we can focus on optimization of our campaigns and not deal with the infrastructure BS -
				and the ROIs are 3 times bigger than before. I guess that every sane media agency on teh planet
				should use this lol..."
				initAnim={initAnim}
			></ClientsItem>
		</div>
	)
}

export default Clients
