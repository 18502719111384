import React from "react"
import { LogoFacebook } from "../../../assets/FABS/FabsSVG"
import Button from "../../Common/Button"
import styles from "./FbasSlide.module.css"

const FbasSlide = ({ onOpenHandler }) => {
	return (
		<div className={`${styles.fBasSlide} slideBody`}>
			<div className={styles.text}>
				<h1>FBAS</h1>
				<p>
					Designed to bring you focus just on media buying and eliminate all the time consuming manual
					processes. <br />
					<br />
					With FBAS you can build your campaigns and launch them to multiple ad accounts - in order to
					scale and keep advertising even if some of your accounts get banned.
				</p>
				<Button
					color="#145FC2"
					className={styles.button}
					onClick={onOpenHandler}
					text="LEARN MORE"
				></Button>
			</div>
			<LogoFacebook className={styles.facebook}></LogoFacebook>
		</div>
	)
}

export default FbasSlide
