import React from "react"
import Button from "../Common/Button"
import styles from "./ContactSlide.module.css"

const ContactSlide = ({ onOpenHandler, setInfo }) => {
	const onCookieHandler = () => {
		setInfo("cookies")
		onOpenHandler()
	}

	const onPrivacyHandler = () => {
		setInfo("privacy")
		onOpenHandler()
	}

	const onServicesHandler = () => {
		setInfo("services")
		onOpenHandler()
	}

	return (
		<footer className={`${styles.contactSlide} slideBody`}>
			<div className={styles.content}>
				<h1>CONTACT US</h1>
				<p>
					You can contact our support team via email or by sending a message to the support chat within
					our working hours. <br></br>
					Monday-Friday 09:00-21:00 EST
				</p>
				<a href="mailto:info@blackscale.media" target="_blank">
					info@blackscale.media
				</a>
				<br></br>
				<a href="https://t.me/BlackScaleMedia_support" target="_blank">
					@BlackScaleMedia_Support
				</a>
				<Button
					color="#337AB7"
					className={styles.button}
					text="CHAT SUPPORT"
					onClick={() => window.Tawk_API.toggle()}
				></Button>
				<p>
					BEWARE OF SCAMMERS & IMPOSTERS in Telegram!<br></br>
					if you have any doubts, please send a message to the chat support here in the site.
				</p>
				<div className={styles.policy}>
					<button onClick={onCookieHandler}>Cookie policy</button>
					<button onClick={onPrivacyHandler}>Privacy Policy</button>
					<button onClick={onServicesHandler}>Terms of Services</button>
				</div>
			</div>
		</footer>
	)
}

export default ContactSlide
