import React from "react"
import styles from "./Button.module.css"

const Button = ({ color, onClick, text = "LEARN MORE", className }) => {
	return (
		<button
			onClick={onClick}
			className={`${styles.button} ${className}`}
			style={{ color: color, border: `${color} 0.19rem solid` }}
		>
			{text}
		</button>
	)
}

export default Button
