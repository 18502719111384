import React from "react"
import styles from "./ServiceBox.module.css"

const ServiceBox = ({ Icon, title, paragraph, backgroundClass }) => {
	return (
		<article className={`${styles.slide} ${backgroundClass}`}>
			<div className={styles.container}>
				<div className={styles.icon}>
					<Icon></Icon>
				</div>
				<h2>{title}</h2>
				<p>{paragraph}</p>
			</div>
		</article>
	)
}

export default ServiceBox
