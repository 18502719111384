import React, { useEffect, useRef, Suspense } from "react"
import Stars from "./Stars"
import Logo from "./Logo"
import Effects from "./Effects"
import { useFrame, useThree } from "@react-three/fiber"
import * as THREE from "three"
import { Text } from "@react-three/drei"

const prlxCursor = { x: 0, y: 0 }
let scrollPrevPos,
	scrollPercent = 0
let scrollCurrentVel = 0
let movedToPosition = false
let i = 0
let scaleCount = 0
let descaleCount = 0
let finished = false

const Scene = ({ scrollRef }) => {
	let theta = 0
	const scene = useThree()
	const { camera } = scene
	const starRef = useRef(null)
	const titleRef = useRef(null)
	const effectRef = useRef(null)
	const prlxRef = useRef(null)
	const rayRef = useRef(null)
	let scale = undefined

	const lerp = (x, y, a) => {
		return (1 - a) * x + a * y
	}

	const scalePercent = (start, end, scroll) => {
		return (scroll - start) / (end - start)
	}

	const translateY = (obj, scroll, dir, sensib = 100) => {
		obj.position.y = scroll * sensib
	}

	const translateX = (obj, scroll, dir, sensib = 100) => {
		obj.position.x = scroll * sensib
	}

	const translateZ = (obj, scroll, dir, sensib = 100) => {
		obj.position.z = scroll * sensib
	}

	const scaleStars = () => {
		if (scaleCount < 40) {
			starRef.current.geometry.scale(1, 1, 1.1)
			scaleCount++
		} else if (descaleCount < 40) {
			finished = true
			starRef.current.geometry.scale(1, 1, 0.9)
			descaleCount++
		}
	}

	const moveToPosition = () => {
		scaleStars()
		if (finished && !movedToPosition) {
			camera.position.z = 1000
			movedToPosition = true
			return true
		}
		i++
	}

	const generalScripts = [
		{
			from: 0.4,
			to: 10,
			func: () => {
				moveToPosition()
			},
		},
		{
			from: 0.48,
			to: 10000,
			func: () => {
				starRef.current.scale.set(1, 1, 1)
				titleRef.current.scale.set(0, 0, 0)
				effectRef.current.passes[1].randX = 0
			},
		},
		{
			from: 0,
			to: 0.48,
			func: () => {
				camera.position.x = 0
				camera.position.y = 0
				camera.rotation.set(0, 0, 0)
				starRef.current.scale.set(0, 0, 0)
				if (titleRef.current) {
					titleRef.current.position.z = scrollPercent * 1000
					titleRef.current.scale.set(1, 1, 1)
				}
			},
		},
	]

	const sceneScripts = [
		{
			from: 0,
			to: 1,
			scene: 0,
			func: (scroll) => {
				translateZ(camera, lerp(10, 0, scroll), 1)
			},
		},
		{
			from: 0,
			to: 1,
			scene: 1,
			func: (scroll) => {
				translateZ(camera, lerp(0, -10, scroll), 1)
			},
		},
		{
			from: 0,
			to: 1,
			scene: 2,
			func: (scroll) => {
				translateZ(camera, lerp(-10, -20, scroll), 1)
			},
		},
		{
			from: 0,
			to: 1,
			scene: 3,
			func: (scroll) => {
				translateZ(camera, lerp(-20, -30, scroll), 1)
			},
		},
		{
			from: 0,
			to: 1,
			scene: 4,
			func: (scroll) => {
				translateZ(camera, lerp(-30, -40, scroll), 1)
			},
		},
		{
			from: 0,
			to: 1,
			scene: 5,
			func: (scroll) => {
				translateZ(camera, lerp(-40, -50, scroll), 1)
			},
		},
		{
			from: 0,
			to: 1,
			scene: 6,
			func: (scroll) => {
				translateZ(camera, lerp(-50, -60, scroll), 1)
			},
		},
		{
			from: 0,
			to: 1,
			scene: 7,
			func: (scroll) => {
				translateZ(camera, lerp(-60, -70, scroll), 1)
			},
		},
	]

	const resizeHandler = () => {
		starRef.current.scale.set(1, 1, 1)
	}

	const scrollHandler = () => {
		scrollPercent =
			(window.scrollY + window.innerHeight / scrollRef.current.clientHeight) /
			scrollRef.current.clientHeight
	}

	const parallaxHandler = (e) => {
		prlxCursor.x = e.clientX / scene.size.width - 0.5
		prlxCursor.y = e.clientY / scene.size.height - 0.5
	}

	useEffect(() => {
		window.addEventListener("scroll", scrollHandler)
		window.onbeforeunload = function () {
			window.scrollTo(0, 0)
		}
		window.addEventListener("mousemove", parallaxHandler)
		return () => {
			window.removeEventListener("mouseover", parallaxHandler)
			window.removeEventListener("scroll", scrollHandler)
		}
	}, [])

	useFrame(() => {
		const r = 5 * Math.sin(THREE.Math.degToRad((theta += 0.01)))
		scrollCurrentVel = Math.abs(scrollPrevPos - scrollPercent)
		scrollPrevPos = scrollPercent

		starRef.current.rotation.set(0, 0, r)

		generalScripts.forEach((e) => {
			if (scrollPercent >= e.from && scrollPercent < e.to) {
				e.func(scrollPercent)
			}
		})

		sceneScripts.forEach((e) => {
			if (scrollPercent >= e.from + e.scene && scrollPercent < e.to + e.scene) {
				e.func(scrollPercent - e.scene)
			}
		})

		// POSIBLE
		// prlxRef.current.position.set(-prlxCursor.x * 100, -prlxCursor.y * -100, 0)
	})

	return (
		<>
			<group ref={prlxRef}>
				<Stars starRef={starRef} rayRef={rayRef} count={10000}></Stars>
				<Suspense fallback={null}>
					<group ref={titleRef} position-y={500}>
						<Text
							fontSize={window.innerWidth > 600 ? 200 : window.innerWidth / 4}
							anchorX="center"
							anchorY="top"
							scale={scale}
							position={[0, window.innerWidth > 600 ? -75 : -300, 2]}
						>
							BLACKSCALE
						</Text>
						<Logo size={0.8} position={[0, -480, 0]} scale={scale}></Logo>
						<Text
							fontSize={window.innerWidth > 600 ? 200 : window.innerWidth / 4}
							anchorX="center"
							anchorY="bottom"
							position={[0, window.innerWidth > 600 ? -950 : -700, 2]}
						>
							MEDIA
						</Text>
					</group>
					<Effects effectRef={effectRef} opacity={0}></Effects>
				</Suspense>
			</group>
		</>
	)
}
export default React.memo(Scene)
