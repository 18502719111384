import React from "react"
import { Link, animateScroll as scroll } from "react-scroll"
import MenuItem from "./MenuItem"
import styles from "./Menu.module.css"

const Menu = () => {
	return (
		<header>
			<nav style={{ position: "fixed", top: 0, zIndex: 10 }} className={styles.nav}>
				<ul>
					<MenuItem
						onClick={() => {
							scroll.scrollToTop()
						}}
					>
						HOME
					</MenuItem>

					<MenuItem scrollTo="features">FEATURES</MenuItem>

					<MenuItem scrollTo="fbas">FBAS</MenuItem>

					<MenuItem scrollTo="bscube">BROWSERCUBE</MenuItem>

					<MenuItem scrollTo="clients" offset={-175}>
						CLIENTS
					</MenuItem>

					<MenuItem scrollTo="futurePlatforms" offset={-175}>
						COMING SOON
					</MenuItem>

					<MenuItem scrollTo="contact">CONTACT</MenuItem>

					<li>
						<a href="https://cloud.blackscale.media"> LOGIN</a>
					</li>
					<li>
						<a href="https://cloud.blackscale.media/guest/register"> REGISTER</a>
					</li>
				</ul>
			</nav>
		</header>
	)
}

export default Menu
