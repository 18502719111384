import React, { useEffect, useContext } from "react"
import { useSpring, animated, config } from "react-spring"
import { ClientsFlap } from "../../assets/Clients/ClientsSVG"
import styles from "./ClientsItem.module.css"
import { MobileCtx } from "../Context/MobileCtx"

const ClientsItem = ({ logo, name, business, text, top, left, initAnim, vip }) => {
	const ty = top ? -1 : 1
	const tx = left ? -1 : 1
	const ctx = useContext(MobileCtx)

	const [spring, api] = useSpring(() => ({
		translateY: "0rem",
		translateX: "0rem",
		config: config.wobbly,
	}))

	useEffect(() => {
		if (initAnim) {
			api.start(() => ({
				translateX: `${(ctx.mobile ? 30 : 10) * tx}rem`,
				translateY: ctx.mobile ? "0rem" : `${10 * ty}rem`,

				config: config.wobbly,
			}))
		} else {
			api.start(() => ({
				translateX: "0rem",
				translateY: "0rem",
				config: config.slow,
			}))
		}
		return () => {}
	}, [initAnim])

	return (
		<animated.article
			className={`${styles.clientsItem} ${top ? styles.clientsTop : styles.clientsBottom} ${
				left ? styles.clientsLeft : styles.clientsRight
			}`}
			style={spring}
		>
			<div className={styles.content}>
				<img src={logo} alt="" />
				<div className={styles.text}>
					<h4 className={styles.name}>{name}</h4>
					<h4 className={styles.business}>{business}</h4>
					<p>{text}</p>
				</div>
			</div>
			{vip && <ClientsFlap className={styles.flap}></ClientsFlap>}
		</animated.article>
	)
}

export default ClientsItem
