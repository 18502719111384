import React, { useRef, useLayoutEffect, useState } from "react"
import { Canvas } from "@react-three/fiber"
import Scene from "./Scene"

const Background = ({ initPos = [0, 0, 1000], sceneHeight }) => {
	const scrollRef = useRef(null)
	const [size, setSize] = useState({ width: window.innerHeight, height: window.innerWidth })

	const resizeHandler = () => {
		if (window.innerWidth < 800) {
			setSize({ width: window.innerWidth, height: window.innerHeight + window.innerHeight / 5 })
		} else {
			setSize({ width: window.innerWidth, height: window.innerHeight })
		}
		if (window.innerWidth !== size.width) {
		}
	}

	useLayoutEffect(() => {
		resizeHandler()
		window.addEventListener("resize", resizeHandler)
		return () => window.removeEventListener("resize", resizeHandler)
	}, [])

	return (
		<>
			<Canvas
				mode="concurrent"
				style={{
					width: size.width,
					height: size.height,
					position: "fixed",
					top: "0",
					zIndex: -1,
				}}
				camera={{ position: initPos, far: 2000 }}
				shadowMap
			>
				<Scene scrollRef={scrollRef} initPos={initPos}></Scene>
			</Canvas>
			<div ref={scrollRef} style={{ position: "relative", width: "100%", height: sceneHeight }}></div>
		</>
	)
}

export default Background
