export const ClientsFlap = ({ className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="160"
			height="84"
			fill="none"
			viewBox="0 0 160 84"
			className={className}
		>
			<path
				fill="#9C1600"
				d="M155.932 1.044C153.209.602 152 15.304 152 15.304h8c0-7.738-2.506-14.006-4.068-14.26zM6 1C2.176.38 0 15.298 0 15.298h9.5C9 8 7.562 1.254 6 1z"
			></path>
			<path
				fill="url(#paint0_linear_448_14)"
				d="M156 1H6c5.77 4.035 3.671 57.639 3.671 57.639L81 83.999l70.28-25.36S149.706 2.729 156 .999z"
			></path>
			<path
				fill="#fff"
				d="M76.12 35l-6.06 14h-3.2l-6.04-14h3.5l4.26 10 4.32-10h3.22zM79.289 35h3.24v14h-3.24V35zM93.911 35c1.24 0 2.314.207 3.22.62.92.413 1.627 1 2.12 1.76.494.76.74 1.66.74 2.7 0 1.027-.246 1.927-.74 2.7-.493.76-1.2 1.347-2.12 1.76-.906.4-1.98.6-3.22.6h-2.82V49h-3.24V35h6.06zm-.18 7.5c.974 0 1.714-.207 2.22-.62.507-.427.76-1.027.76-1.8 0-.787-.253-1.387-.76-1.8-.506-.427-1.246-.64-2.22-.64h-2.64v4.86h2.64z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_448_14"
					x1="81"
					x2="81"
					y1="1"
					y2="84"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CC1D00"></stop>
					<stop offset="1" stopColor="#CC1D00" stopOpacity="0.2"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
