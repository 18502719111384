import React, { useEffect, useContext } from "react"
import { BsCubeFlap } from "../../../assets/BrowserCube/BsCubeSVG"
import styles from "./BsCubeTask.module.css"
import { useSpring, animated, config } from "react-spring"
import { MobileCtx } from "../../Context/MobileCtx"

const BsCubeTask = ({ initAnim }) => {
	const ctx = useContext(MobileCtx)
	const [spring, api] = useSpring(() => ({
		opacity: 1,
		translateX: "0rem",
		translateY: "0rem",
		config: config.slow,
	}))

	useEffect(() => {
		if (initAnim) {
			api.start({
				opacity: 0,
				translateX: ctx.mobile ? "0rem" : "20rem",
				translateY: ctx.mobile ? "-20rem" : "0rem",
			})
		} else {
			api.start({ opacity: 1, translateX: "0", translateY: "0rem" })
		}
		return () => {}
	}, [initAnim])

	return (
		<animated.div className={styles.bsCubeTask} style={spring}>
			<div className={styles.container}>
				<BsCubeFlap className={styles.flap}></BsCubeFlap>
				<div className={styles.text}>
					<h3>BROWSERCUBE TASK</h3>
					<p>
						Manage social accounts with smart automation.
					</p>
				</div>
			</div>
		</animated.div>
	)
}

export default BsCubeTask
