import React, { useMemo, useEffect } from "react"
import * as THREE from "three"

const starObj = new THREE.Object3D()
const starGeo = new THREE.SphereBufferGeometry(1, 5, 5)
const starMat = new THREE.MeshStandardMaterial({ color: 0xffffff, emissive: 0xffffff })

const Stars = ({ starRef, count }) => {
	const coords = useMemo(() => {
		const coords = new Array(count)
			.fill()
			.map(() => [
				Math.random() * 1000 - 500,
				Math.random() * 1000 - 500,
				Math.random() * 12000 - 8000,
			])
		return coords
	}, [])

	const StarList = () => {
		useEffect(() => {
			coords.map(([p1, p2, p3], i) => {
				starObj.position.set(p1, p2, p3)
				starObj.updateMatrix()
				starRef.current.setMatrixAt(i, starObj.matrix)
			})
			starRef.current.instanceMatrix.needsUpdate = true
		}, [])

		return (
			<>
				<instancedMesh ref={starRef} args={[null, null, count]} scale={0}>
					<primitive attach="geometry" object={starGeo}></primitive>
					<primitive attach="material" object={starMat}></primitive>
				</instancedMesh>
			</>
		)
	}

	return (
		<>
			<StarList position={[0, 0, 0]}></StarList>
		</>
	)
}

export default React.memo(Stars)
