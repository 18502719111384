import React from "react"
import styles from "./Stats.module.css"
import Graph from "./Graph"
import CheckBox from "./CheckBox"

const Stats = ({ initAnim }) => {
	return (
		<article className={`${styles.stats} slideBody`}>
			<div className={styles.content}>
				<CheckBox></CheckBox>
				<Graph initAnim={initAnim}></Graph>
			</div>
		</article>
	)
}

export default Stats
