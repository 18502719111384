import React from "react"
import styles from "./PDFItem.module.css"

const PDFItem = ({ pdf }) => {
	return (
		<object data={pdf} type="application/pdf" className={styles.pdfContainer}>
			<p>Can't display PDF. Download instead</p>
			<a href={pdf} download className={styles.button}>
				DOWNLOAD
			</a>
		</object>
	)
}

export default PDFItem
