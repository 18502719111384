import React from "react"
import styles from "./slogan.module.css"

const Services = () => {
	return (
		<div className={styles.slide}>
			<h1>SCALE SOCIAL</h1>
			<h1>CAMPAIGNS</h1>
			<h2>WITH ONE CLICK</h2>
		</div>
	)
}

export default Services
