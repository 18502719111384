import React, { useEffect, useState } from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import { useCookies } from 'react-cookie';
import { MobileCtx } from "./Components/Context/MobileCtx"
import ExpandZ from "./Utilities/ExpandZ"
import Background from "./Components/Background/Background"
import Menu from "./Components/Menu/Menu"
import Slogan from "./Components/Slogan/Slogan"
import Services from "./Components/Services/Services"
import Stats from "./Components/Stats/Stats"
import Fbas from "./Components/FBAS/Fbas"
import BsCube from "./Components/BrowserCube/BsCube"
import Clients from "./Components/Clients/Clients"
import FuturePlatforms from "./Components/FuturePlatforms/FuturePlatforms"
import Contact from "./Components/Contact/Contact"

function App() {
	const [isMobile, setIsMobile] = useState({ mobile: false, width: undefined, height: undefined })
	const [isResizing, setIsResizing] = useState(false)
	const [cookies, setCookie] = useCookies(['blackscale-ref']);
	const animateAt = isMobile.mobile ? 0.6 : 0.5

	const resizeWindow = () => {
		if (window.innerWidth !== isMobile.width) {
			if (window.innerWidth < 800) {
				setIsMobile({ mobile: true, width: window.innerWidth, height: window.innerHeight })
			} else {
				setIsMobile({ mobile: false, width: window.innerWidth, height: window.innerHeight })
			}
		}
	}

	const onResizeHandler = () => {
		setIsResizing((e) => !e)
	}

	useEffect(() => {
		onResizeHandler()
		window.addEventListener("resize", onResizeHandler)
		return () => {
			window.removeEventListener("resize", onResizeHandler)
		}
	}, [])

	useEffect(() => {
		resizeWindow()
	}, [isResizing])
	
	useEffect(() => {
		let queryParams = new URLSearchParams(window.location.search);
		let cookie_domain = 'blackscale.media';
		let expire_date = new Date();
		expire_date.setDate(expire_date.getDate()+30);
		if(queryParams.has('ref')){
			setCookie('ref', queryParams.get('ref'), { path: '/', domain: cookie_domain, expires: expire_date});
		}
		if(queryParams.has('utm_id')){
			setCookie('utm_id', queryParams.get('utm_id'), { path: '/', domain: cookie_domain, expires: expire_date});
		}
		if(queryParams.has('utm_source')){
			setCookie('utm_source', queryParams.get('utm_source'), { path: '/', domain: cookie_domain, expires: expire_date});
		}
		if(queryParams.has('utm_medium')){
			setCookie('utm_medium', queryParams.get('utm_medium'), { path: '/', domain: cookie_domain, expires: expire_date});
		}
		if(queryParams.has('utm_campaign')){
			setCookie('utm_campaign', queryParams.get('utm_campaign'), { path: '/', domain: cookie_domain, expires: expire_date});
		}
		if(queryParams.has('utm_term')){
			setCookie('utm_term', queryParams.get('utm_term'), { path: '/', domain: cookie_domain, expires: expire_date});
		}
		if(queryParams.has('utm_content')){
			setCookie('utm_content', queryParams.get('utm_content'), { path: '/', domain: cookie_domain, expires: expire_date});
		}
	}, [])

	return (
		<>
			<MobileCtx.Provider value={isMobile}>
				<ParallaxProvider>
					<Menu></Menu>
					<Background sceneHeight="1500px"></Background>

					<ExpandZ height="1500px" Component={Slogan}></ExpandZ>

					<ExpandZ height="1500px" link="features" Component={Services} animateAt={animateAt}></ExpandZ>

					<ExpandZ height="1500px" Component={Stats} animateAt={0.1}></ExpandZ>

					<ExpandZ height="1500px" link="fbas" Component={Fbas}></ExpandZ>

					<ExpandZ height="1500px" link="bscube" Component={BsCube} animateAt={animateAt}></ExpandZ>

					<ExpandZ height="1500px" link="clients" Component={Clients} animateAt={0.4}></ExpandZ>

					<ExpandZ height="1500px" link="futurePlatforms" Component={FuturePlatforms}></ExpandZ>

					<ExpandZ height="1500px" link="contact" Component={Contact}></ExpandZ>
				</ParallaxProvider>
			</MobileCtx.Provider>
		</>
	)
}

export default App
