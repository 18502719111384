import React from "react"
import { LogoTikTok, LogoTwitter, LogoGoogle } from "../../assets/FuturePlatforms/FuturePlatforms"
import styles from "./FuturePlatforms.module.css"

const FuturePlatforms = () => {
	return (
		<div className={styles.slide}>
			<h1>FUTURE PLATFORMS SUPPORT</h1>
			<p>
				We are constantly working to improve our support with all the best performing social platforms.
			</p>
			<div className={styles.logos}>
				<LogoTikTok className={styles.logoTikTok}> </LogoTikTok>
				<LogoTwitter className={styles.logoTwitter}></LogoTwitter>
				<LogoGoogle className={styles.logoGoogle}></LogoGoogle>
			</div>
			<h2>COMING SOON!</h2>
		</div>
	)
}

export default FuturePlatforms
