import React, { useEffect, useState, useContext } from "react"
import { MobileCtx } from "../../Context/MobileCtx"
import { FbasModalImage } from "../../../assets/FABS/FabsSVG"
import FbasCarousel from "./FbasCarousel"
import styles from "./FbasModal.module.css"
import Button from "../../Common/Button"
import "swiper/css"

const FbasModal = ({ onCloseHandler }) => {
	const [imgOp, setImgOp] = useState(true)
	const ctx = useContext(MobileCtx)

	useEffect(() => {
		setTimeout(() => {
			setImgOp(false)
		}, 1300)
	}, [])

	return (
		<>
			<div className={`slideBody ${styles.fbasModal} `}>
				<div className={styles.container}>
					<h1>FBAS</h1>
					<FbasCarousel active={imgOp}></FbasCarousel>
					<FbasModalImage
						className={styles.imageFlip}
						style={{ opacity: imgOp ? 1 : 0, display: ctx.mobile ? "none" : "block" }}
					></FbasModalImage>
				</div>
				<Button
					color="#145FC2"
					className={styles.button}
					onClick={onCloseHandler}
					text="CLOSE"
				></Button>
			</div>
		</>
	)
}

export default FbasModal
