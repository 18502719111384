import React, { useState } from "react"
import ReactDOM from "react-dom"
import { useSpring, animated } from "react-spring"
import { animateScroll as scroll } from "react-scroll/modules"
import styles from "./Modal.module.css"

function preventScroll(e) {
	e.preventDefault()
	e.stopPropagation()
}

const Modal = ({ Slide, Modal, scrollTo, initAnim }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [currentScroll, setCurrentScroll] = useState(null)
	const [info, setInfo] = useState(false)

	const { transform, opacity } = useSpring({
		opacity: isOpen ? 1 : 0,
		transform: `perspective(600px) rotateX(${isOpen ? 180 : 0}deg)`,
		config: { mass: 1, tension: 1000, friction: 80 },
	})

	const onOpenHandler = () => {
		console.log(window.scrollY)
		setCurrentScroll(window.scrollY)
		scroll.scrollTo(scrollTo, {
			smooth: true,
			ignoreCancelEvents: true,
		})
		setIsOpen(true)
		window.addEventListener("wheel", preventScroll, { passive: false })
		window.addEventListener("touchmove", preventScroll, { passive: false })
	}

	const onCloseHandler = () => {
		scroll.scrollTo(currentScroll, { smooth: true, ignoreCancelEvents: true })
		setIsOpen(false)
		window.removeEventListener("wheel", preventScroll, { passive: true })
		window.removeEventListener("touchmove", preventScroll, { passive: true })
	}

	return (
		<>
			<div className={styles.container}>
				<animated.article
					style={{
						opacity: opacity.to((x) => 1 - x),
						transform,
					}}
					className={isOpen ? styles.inactive : styles.active}
				>
					<Slide onOpenHandler={onOpenHandler} initAnim={initAnim} setInfo={setInfo}></Slide>
				</animated.article>

				{isOpen && (
					<animated.article style={{ opacity, transform, rotateX: "180deg" }}>
						<Modal onCloseHandler={onCloseHandler} initAnim={initAnim} info={info}></Modal>
						{ReactDOM.createPortal(<div className={styles.hideMenu}></div>, document.body)}
					</animated.article>
				)}
			</div>
		</>
	)
}

export default React.memo(Modal)
