import React from "react"
import BsCubeDesktop from "./BsCubeDesktop"
import BsCubeTask from "./BsCubeTask"
import styles from "./BsCubeSlide.module.css"
import BsCubeLogo from "../../../assets/BrowserCube/BsCubeLogo.png"

const BrowserCubeSlide = ({ onOpenHandler, initAnim }) => {
	return (
		<div className={`${styles.bsCubeSlide} slideBody`}>
			<img src={BsCubeLogo} alt="BsCubeLogo" className={styles.logo} />
			<BsCubeDesktop onOpenHandler={onOpenHandler} initAnim={initAnim}></BsCubeDesktop>
			<BsCubeTask initAnim={initAnim}></BsCubeTask>
		</div>
	)
}

export default BrowserCubeSlide
