import React, { useEffect, useContext } from "react"
import styles from "./Services.module.css"
import ServiceBox from "./ServiceBox"
import { useSpring, animated, config } from "react-spring"
import { AutomationsIcon, MassLaunchIcon, StatsMasterIcon } from "../../assets/Services/ServicesSVG"
import { MobileCtx } from "../Context/MobileCtx"

const Services = ({ initAnim }) => {
	const ctx = useContext(MobileCtx)
	const [spring, api] = useSpring(() => ({
		gap: ctx.mobile ? "0rem" : "3rem",
	}))

	useEffect(() => {
		if (initAnim) {
			api.start(() => ({ gap: "30rem" }))
		} else {
			api.start(() => ({ gap: ctx.mobile ? "0rem" : "3rem" }))
		}
	}, [initAnim])

	return (
		<animated.div className={styles.services} style={spring}>
			<ServiceBox
				backgroundClass={styles.autoFarm}
				Icon={AutomationsIcon}
				title="AUTOMATIONS"
				paragraph="With a simple drag&drop interface, you can easily manage all your accounts, bms and associated assets."
				initAnim={initAnim}
			></ServiceBox>

			<ServiceBox
				backgroundClass={styles.massLaunch}
				Icon={MassLaunchIcon}
				title="MASS LAUNCH"
				paragraph="Create your campaign bundle, and launch it on multiple accounts at once. 
				LP Protection, AI Webites and everything will be automatically done for you."
			></ServiceBox>

			<ServiceBox
				backgroundClass={styles.statsMaster}
				Icon={StatsMasterIcon}
				title="STATS MASTER"
				paragraph="Track and manage all your accounts' health and campaigns' performance with live updates from one dashboard."
				initAnim={initAnim}
			></ServiceBox>
		</animated.div>
	)
}

export default Services
