import React, { useEffect } from "react"
import { useSpring, to, animated, config } from "react-spring"
import styles from "./GraphItem.module.css"

const GraphItem = ({ title, percent, color = "#337AB7", delay, initAnim }) => {
	const [props, api] = useSpring(() => ({
		width: "0%",
		num: 0,
		config: config.molasses,
		delay: delay,
	}))

	useEffect(() => {
		if (initAnim) {
			api.start({ width: `${percent}%`, num: percent })
		} else {
			api.start({ width: `0%`, num: 0 })
		}
		return () => {}
	}, [initAnim])

	return (
		<div className={styles.graphItem}>
			<div className={styles.textCont}>
				<p>{title}</p>
				<animated.p>{props.num.to((x) => `${x.toFixed(0)}%`)}</animated.p>
			</div>
			<animated.div className={styles.bar} style={{ backgroundColor: color, ...props }}></animated.div>
		</div>
	)
}

export default GraphItem
