import React, { useState, useRef, useContext } from "react"
import { MobileCtx } from "../Components/Context/MobileCtx"
import styles from "./expandZ.module.css"
import { Parallax } from "react-scroll-parallax"
import { a, useSpring } from "react-spring"
import { config } from "@react-spring/three"

const ExpandZ = ({ Component, height = "3000px", animateAt, link }) => {
	const contRef = useRef(null)
	const [initAnim, setInitAnim] = useState(false)
	const ctx = useContext(MobileCtx)

	const lerp = (x, y, a) => {
		return (1 - a) * x + a * y
	}

	const [expand, api] = useSpring(() => ({
		scale: 0,
		opacity: 1,
	}))

	const scalePercent = (start, end, e) => {
		return (e - start) / (end - start)
	}

	const onEnterHandler = () => {
		contRef.current.classList.add(styles.active)
		contRef.current.classList.remove(styles.inactive)
	}

	const onExitHandler = () => {
		contRef.current.classList.add(styles.inactive)
		contRef.current.classList.remove(styles.active)
	}

	const scrollDesctop = (e) => {
		if (window.scrollY > 12000) {
			return
		}
		if (e < 0.3) {
			api.start(() => ({
				scale: lerp(0, 1, scalePercent(0, 0.3, e)),
				opacity: 1,
			}))
		} else if (e < 0.7) {
			api.start(() => ({
				scale: lerp(1, 2, scalePercent(0.3, 0.7, e)),
				opacity: 1,
			}))
		} else {
			api.start(() => ({ scale: lerp(2, 7, scalePercent(0.7, 1, e)) }))
		}
		if (e > 0.65) {
			api.start(() => ({ opacity: 0, config: config.stiff }))
		}
	}

	const scrollMobile = (e) => {
		if (window.scrollY > 12000) {
			return
		}
		if (e < 0.2 && !(e > 0.5)) {
			api.start(() => ({
				opacity: 1,
				scale: lerp(0, 0.9, scalePercent(0, 0.2, e)),
			}))
		} else if (e < 0.5) {
			api.start(() => ({
				scale: lerp(0.9, 1, scalePercent(0.2, 0.5, e)),
				opacity: 1,
			}))
		} else if (!(e > 0.9)) {
			api.start(() => ({
				scale: lerp(1, 2, scalePercent(0.5, 1, e)),
				opacity: 1,
			}))
		}
		if (e > 0.7) {
			api.start(() => ({ opacity: 0 }))
		}
	}

	const onScrollHandler = (e) => {
		if (ctx.mobile) {
			scrollMobile(e)
		} else {
			scrollDesctop(e)
		}
		if (animateAt) {
			if (animateAt <= e) {
				setInitAnim(true)
			} else {
				setInitAnim(false)
			}
		}
	}

	return (
		<>
			<Parallax onEnter={onEnterHandler} onExit={onExitHandler} onProgressChange={onScrollHandler}>
				<div style={{ height: height }} className={styles.reference} id={link}></div>
			</Parallax>

			<a.section
				ref={contRef}
				className={styles.container}
				style={{
					translateY: "-50%",
					top: ctx.height / 2 + (ctx.mobile ? ctx.height / 20 : 0),
					...expand,
				}}
			>
				<Component initAnim={initAnim}></Component>
			</a.section>
		</>
	)
}

export default React.memo(ExpandZ)
