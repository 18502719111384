import React, { useEffect } from "react"
import { extend, useFrame, useThree } from "@react-three/fiber"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass"

extend({ EffectComposer, RenderPass, GlitchPass })

const Effects = ({ effectRef }) => {
	const { gl, scene, camera, size } = useThree()

	useEffect(() => {
		effectRef.current.setSize(size.width, size.height)
		effectRef.current.needsUpdate = true
	}, [size])
	useFrame(() => effectRef.current.render(), 1)

	return (
		<>
			<effectComposer ref={effectRef} args={[gl]}>
				<renderPass attachArray="passes" args={[scene, camera]} />
				<glitchPass attachArray="passes" randX={50} />
			</effectComposer>
		</>
	)
}

export default React.memo(Effects)
