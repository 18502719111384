import React, { useState } from "react"
import { Link, animateScroll as scroll } from "react-scroll"
import styles from "./MenuItem.module.css"

const MenuItem = ({ children, scrollTo, onClick, offset }) => {
	const [animate, setAnimate] = useState(false)

	return (
		<li
			onClick={onClick}
			className={animate ? styles.animate : null}
			onMouseEnter={() => {
				setAnimate(true)
			}}
			onMouseLeave={() => {
				setAnimate(false)
			}}
		>
			{scrollTo ? null : children}
			{scrollTo && (
				<Link to={scrollTo} smooth={true} offset={offset}>
					{children}
				</Link>
			)}
		</li>
	)
}

export default MenuItem
