import React from "react"
import styles from "./CheckBox.module.css"
import CheckBoxItem from "./CheckBoxItem"

const CheckBox = () => {
	return (
		<div className={styles.checkBox}>
			<CheckBoxItem>LP Protection</CheckBoxItem>
			<CheckBoxItem>Fast Proxies</CheckBoxItem>
			<CheckBoxItem>AI Websites</CheckBoxItem>
			<CheckBoxItem>Cards Solution</CheckBoxItem>
		</div>
	)
}

export default CheckBox
