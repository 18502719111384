import React, { useEffect } from "react"
import styles from "./ContactModal.module.css"
import PDFItem from "./PDFItem"
import Button from "../../Common/Button"
import cookies from "../../../assets/Contact/PDF/Cookie_policy.pdf"
import privacy from "../../../assets/Contact/PDF/Privacy_Policy.pdf"
import terms from "../../../assets/Contact/PDF/Terms_and_Conditions.pdf"

const ContactModal = ({ onCloseHandler, info }) => {
	useEffect(() => {
		document.body.style.overflow = "hidden"
		return () => {
			document.body.style.overflow = "auto"
		}
	}, [])

	return (
		<div className={`${styles.contactModal} slideBody`}>
			<div className={styles.content}>
				{info === "cookies" && <PDFItem pdf={cookies}></PDFItem>}
				{info === "privacy" && <PDFItem pdf={privacy}></PDFItem>}
				{info === "services" && <PDFItem pdf={terms}></PDFItem>}
				<Button onClick={onCloseHandler} className={styles.button} text="CLOSE" color="#337AB7" />
			</div>
		</div>
	)
}

export default ContactModal
