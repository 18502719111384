import React from "react"
import GraphItem from "./GraphItem"
import styles from "./Graph.module.css"

const Graph = ({ initAnim }) => {
	return (
		<div className={styles.graph}>
			<GraphItem
				title="Accounts Prepared"
				percent={100}
				color={"#337AB7"}
				initAnim={initAnim}
			></GraphItem>

			<GraphItem
				title="Accounts Launched"
				percent={80}
				color={"#5CB85C"}
				delay={300}
				initAnim={initAnim}
			></GraphItem>

			<GraphItem
				title="Accounts Banned"
				percent={20}
				color={"#D9534F"}
				delay={600}
				initAnim={initAnim}
			></GraphItem>
		</div>
	)
}

export default Graph
