export const LogoTikTok = ({ className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="296"
			height="144"
			fill="none"
			viewBox="0 0 296 144"
			className={className}
		>
			<path
				fill="#fff"
				d="M147.976 143.81c39.778 0 71.988-32.193 71.945-71.905C219.877 32.193 187.596 0 147.818 0 108.041 0 75.83 32.193 75.874 71.905c.044 39.712 32.325 71.905 72.102 71.905z"
			></path>
			<path
				fill="#fff"
				d="M280.578 74.163c11.349 1.442 15.673 2.599 14.931 3.526-3.889 4.86-147.08 3.386-147.08 3.386l.041-78.804c34.925 0 54.918 19.572 68.332 42.116 13.413 22.544 34.809 26.097 63.776 29.776zM15.75 74.165c-71.169 9.001 132.678 6.91 132.678 6.91V2.271c-35.076 0-55.156 19.573-68.627 42.117C66.33 66.932 44.84 70.486 15.749 74.165z"
			></path>
			<path
				fill="#000"
				d="M148.028 127.522c30.743 0 55.665-24.872 55.665-55.554 0-30.681-24.922-55.554-55.665-55.554-30.742 0-55.664 24.873-55.664 55.554 0 30.682 24.922 55.554 55.664 55.554z"
			></path>
			<path
				stroke="#fff"
				strokeWidth="2"
				d="M197.503 71.67c0 27.252-22.092 49.343-49.344 49.343-27.251 0-49.344-22.09-49.344-49.343 0-27.252 22.093-49.344 49.344-49.344 27.252 0 49.344 22.092 49.344 49.344z"
			></path>
			<path
				fill="#EE1D52"
				fillRule="evenodd"
				d="M162.52 63.21c3.913 2.938 8.707 4.667 13.884 4.667V57.413c-.98 0-1.957-.107-2.916-.32v8.236c-5.177 0-9.97-1.728-13.884-4.666v21.354c0 10.683-8.244 19.342-18.414 19.342-3.795 0-7.322-1.205-10.252-3.272 3.344 3.592 8.007 5.82 13.167 5.82 10.17 0 18.415-8.66 18.415-19.343V63.211zm3.597-10.556c-2-2.295-3.313-5.26-3.597-8.539V42.77h-2.763c.695 4.167 3.068 7.727 6.36 9.885zM137.371 89.89a9.104 9.104 0 01-1.719-5.356c0-4.886 3.772-8.847 8.424-8.847.867 0 1.729.14 2.555.414V65.403a17.68 17.68 0 00-2.914-.176v8.327a8.069 8.069 0 00-2.557-.414c-4.652 0-8.423 3.96-8.423 8.847 0 3.455 1.885 6.446 4.634 7.903z"
				clipRule="evenodd"
			></path>
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M159.604 60.663c3.914 2.938 8.707 4.666 13.884 4.666v-8.236c-2.89-.647-5.448-2.233-7.371-4.44-3.292-2.157-5.665-5.717-6.36-9.884h-7.258v41.795c-.016 4.872-3.781 8.818-8.423 8.818-2.736 0-5.166-1.37-6.705-3.492-2.749-1.457-4.634-4.448-4.634-7.903 0-4.886 3.771-8.847 8.423-8.847.892 0 1.751.145 2.557.414v-8.327c-9.991.217-18.027 8.792-18.027 19.337 0 5.265 2.001 10.037 5.248 13.523 2.93 2.067 6.457 3.272 10.252 3.272 10.17 0 18.414-8.66 18.414-19.342V60.663z"
				clipRule="evenodd"
			></path>
			<path
				fill="#69C9D0"
				fillRule="evenodd"
				d="M173.488 57.093v-2.228a13.392 13.392 0 01-7.371-2.211c1.957 2.25 4.534 3.802 7.371 4.438zm-13.731-14.324a16.143 16.143 0 01-.153-1.201v-1.346h-10.021v41.795c-.016 4.872-3.78 8.817-8.423 8.817-1.363 0-2.65-.34-3.789-.944 1.539 2.122 3.969 3.492 6.705 3.492 4.642 0 8.407-3.946 8.423-8.818V42.77h7.258zm-16.04 22.458v-2.37a17.72 17.72 0 00-2.527-.18c-10.171 0-18.416 8.659-18.416 19.34 0 6.697 3.24 12.599 8.164 16.07-3.247-3.486-5.248-8.258-5.248-13.523 0-10.545 8.036-19.12 18.027-19.337z"
				clipRule="evenodd"
			></path>
			<path
				fill="#fff"
				fillOpacity="0.5"
				d="M75.8 75.9l143.589.428-.001 4.883L75.8 81.209v-5.31z"
			></path>
			<path
				fill="#000"
				d="M295.199 78.61a.746.746 0 10-.236-1.473l.236 1.473zM1.279 77.134a.747.747 0 10-.198 1.479l.199-1.479zm293.684.003c-7.572 1.21-26.991 2.183-52.607 2.865-25.59.682-57.304 1.074-89.42 1.131C88.674 81.25 22.89 80.027 1.28 77.134l-.2 1.479c21.735 2.91 87.639 4.128 151.859 4.013 32.125-.058 63.851-.45 89.457-1.132 25.58-.681 45.112-1.654 52.803-2.884l-.236-1.473z"
			></path>
		</svg>
	)
}

export const LogoTwitter = ({ className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="266"
			height="130"
			fill="none"
			viewBox="0 0 266 130"
			className={className}
		>
			<path
				fill="#fff"
				d="M132.732 129.14c35.718 0 64.642-28.909 64.603-64.57C197.295 28.91 168.308 0 132.59 0 96.871 0 67.948 28.909 67.987 64.57c.039 35.661 29.026 64.57 64.745 64.57z"
			></path>
			<path
				fill="#fff"
				d="M251.802 66.598c10.191 1.295 14.074 2.334 13.408 3.166-3.493 4.365-132.072 3.041-132.072 3.041l.037-70.766c31.361 0 49.314 17.576 61.359 37.82 12.044 20.244 31.257 23.435 57.268 26.74z"
			></path>
			<path
				fill="#fff"
				d="M13.999 66.6c-63.906 8.083 119.139 6.205 119.139 6.205V2.039c-31.497 0-49.527 17.577-61.624 37.821C59.418 60.105 40.122 63.296 14 66.6z"
			></path>
			<path
				fill="#000"
				d="M132.779 114.514c27.605 0 49.984-22.335 49.984-49.887S160.384 14.74 132.779 14.74 82.795 37.075 82.795 64.627s22.379 49.887 49.984 49.887z"
			></path>
			<path
				stroke="#fff"
				d="M177.206 64.359c0 24.472-19.838 44.31-44.308 44.31-24.471 0-44.31-19.838-44.31-44.31s19.839-44.31 44.31-44.31c24.47 0 44.308 19.837 44.308 44.31z"
			></path>
			<path
				fill="#00AEEF"
				d="M172.99 64.863c0 22.246-18.034 40.279-40.279 40.279-22.246 0-40.28-18.033-40.28-40.279 0-22.245 18.034-40.279 40.28-40.279 22.245 0 40.279 18.034 40.279 40.28z"
			></path>
			<path
				fill="#fff"
				d="M121.897 90.8c22.717 0 35.14-19.683 35.14-36.752 0-.559-.011-1.115-.035-1.67a25.789 25.789 0 006.161-6.688 23.781 23.781 0 01-7.093 2.034c2.55-1.6 4.508-4.13 5.431-7.145a24.114 24.114 0 01-7.842 3.136c-2.254-2.511-5.462-4.082-9.015-4.082-6.82 0-12.351 5.785-12.351 12.916 0 1.013.108 2 .32 2.945-10.264-.54-19.366-5.68-25.458-13.496a13.34 13.34 0 00-1.672 6.492c0 4.482 2.18 8.438 5.496 10.753a11.855 11.855 0 01-5.594-1.616l-.001.165c0 6.256 4.257 11.479 9.908 12.662a11.772 11.772 0 01-5.578.222c1.572 5.132 6.132 8.867 11.537 8.971-4.227 3.465-9.552 5.53-15.339 5.53-.984 0-1.968-.06-2.946-.18 5.466 3.665 11.956 5.804 18.931 5.804z"
			></path>
			<path
				fill="#fff"
				fillOpacity="0.5"
				d="M67.92 68.157l128.938.385-.002 4.385-128.935-.002v-4.767z"
			></path>
			<path
				fill="#000"
				d="M264.933 70.591a.67.67 0 10-.213-1.323l.213 1.323zM1.006 69.266a.67.67 0 10-.179 1.328l.179-1.328zm263.714.002c-6.799 1.087-24.236 1.96-47.238 2.574-22.979.612-51.456.964-80.295 1.015-57.705.104-116.776-.993-136.181-3.591l-.179 1.328c19.517 2.613 78.695 3.707 136.362 3.603 28.847-.051 57.336-.403 80.329-1.016 22.969-.612 40.508-1.486 47.415-2.59l-.213-1.323z"
			></path>
		</svg>
	)
}

export const LogoGoogle = ({ className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="177"
			height="86"
			fill="none"
			viewBox="0 0 177 86"
			className={className}
		>
			<path
				fill="#fff"
				d="M88.024 85.81c23.728 0 42.903-19.21 42.828-42.905C130.777 19.209 111.479 0 87.751 0 64.022 0 44.847 19.21 44.922 42.905c.076 23.696 19.373 42.905 43.102 42.905z"
			></path>
			<path
				fill="#fff"
				d="M167.056 44.253c6.772.86 9.352 1.55 8.91 2.103-2.321 2.899-87.762 2.02-87.762 2.02l.025-47.01c20.839 0 32.769 11.676 40.773 25.124 8.003 13.448 20.77 15.568 38.054 17.763zM9.036 44.254c-42.465 5.37 79.168 4.122 79.168 4.122V1.366c-20.93 0-32.911 11.676-40.95 25.125-8.037 13.448-20.86 15.568-38.218 17.763z"
			></path>
			<path
				fill="#000"
				d="M87.965 76.083c18.344 0 33.215-14.837 33.215-33.14s-14.871-33.14-33.215-33.14-33.214 14.837-33.214 33.14 14.87 33.14 33.214 33.14z"
			></path>
			<path
				stroke="#fff"
				d="M117.487 42.765c0 16.257-13.183 29.435-29.443 29.435S58.6 59.022 58.6 42.765c0-16.257 13.182-29.435 29.443-29.435 16.26 0 29.443 13.178 29.443 29.435z"
			></path>
			<path
				fill="#FBBC04"
				d="M67.867 51.393l13.965-24.524 12.102 7.083L79.97 58.476l-12.103-7.083z"
			></path>
			<path
				fill="#4285F4"
				d="M108.016 51.307L94.042 26.774c-1.804-3.458-6.031-4.78-9.443-2.951-3.412 1.829-4.714 6.114-2.911 9.573.079.151.163.3.252.446l13.975 24.533c1.884 3.413 6.142 4.632 9.509 2.721 3.367-1.91 4.569-6.227 2.685-9.64l-.093-.164v.015z"
			></path>
			<path
				fill="#34A853"
				d="M73.987 61.963c3.859 0 6.987-3.172 6.987-7.084 0-3.911-3.128-7.083-6.987-7.083S67 50.968 67 54.88c0 3.911 3.128 7.083 6.987 7.083z"
			></path>
			<path
				fill="#000"
				d="M175.884 46.752a.445.445 0 10-.141-.879l.141.88zM.505 45.872a.446.446 0 10-.118.882l.118-.882zm175.238.002c-4.518.722-16.105 1.302-31.39 1.709-15.269.407-34.192.64-53.356.675-38.344.069-77.597-.66-90.492-2.386l-.118.882c12.969 1.736 52.292 2.463 90.612 2.394 19.169-.034 38.1-.268 53.378-.675 15.263-.407 26.918-.987 31.507-1.72l-.141-.88z"
			></path>
			<path
				fill="#fff"
				fillOpacity="0.5"
				d="M44.867 45.288l85.679.256-.001 2.913-85.678-.001v-3.168z"
			></path>
		</svg>
	)
}
