import React, { useContext } from "react"
import ReactDOM from "react-dom"
import { MobileCtx } from "../../Context/MobileCtx"
import { useSpring, animated } from "react-spring"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Zoom } from "swiper"
import image1 from "../../../assets/FABS/Modal/image1.png"
import image2 from "../../../assets/FABS/Modal/image2.png"
import image3 from "../../../assets/FABS/Modal/image3.png"
import image4 from "../../../assets/FABS/Modal/image4.png"
import image5 from "../../../assets/FABS/Modal/image5.png"
import image6 from "../../../assets/FABS/Modal/image6.png"
import image7 from "../../../assets/FABS/Modal/image7.png"
import image8 from "../../../assets/FABS/Modal/image8.png"
import image9 from "../../../assets/FABS/Modal/image9.png"
import image10 from "../../../assets/FABS/Modal/image10.png"
import styles from "./FbasCarousel.module.css"
import "swiper/css/zoom"
import "swiper/css/pagination"
import "swiper/css"

const FbasCarousel = () => {
	const ctx = useContext(MobileCtx)
	const spring = useSpring({
		from: {
			opacity: 0,
			scaleY: ctx.mobile ? 0 : 1,
		},
		to: {
			opacity: 1,
			scaleY: 1,
		},
		delay: 1100,
		config: { duration: 200 },
	})

	return ReactDOM.createPortal(
		<animated.div style={{ ...spring, height: 0 }}>
			<Swiper
				spaceBetween={200}
				slidesPerView={1}
				className={styles.carousel}
				pagination={{}}
				zoom={true}
				modules={[Pagination, Zoom]}
			>
				<SwiperSlide>
					<div className="swiper-zoom-container">
						<img src={image1} alt="image1" />
					</div>
				</SwiperSlide>

				<SwiperSlide>
					<div className="swiper-zoom-container">
						<img src={image2} alt="image2" />
					</div>
				</SwiperSlide>

				<SwiperSlide>
					<div className="swiper-zoom-container">
						<img src={image3} alt="image3" />
					</div>
				</SwiperSlide>

				<SwiperSlide>
					<div className="swiper-zoom-container">
						<img src={image4} alt="image4" />
					</div>
				</SwiperSlide>

				<SwiperSlide>
					<div className="swiper-zoom-container">
						<img src={image5} alt="image4" />
					</div>
				</SwiperSlide>

				<SwiperSlide>
					<div className="swiper-zoom-container">
						<img src={image6} alt="image4" />
					</div>
				</SwiperSlide>

				<SwiperSlide>
					<div className="swiper-zoom-container">
						<img src={image7} alt="image4" />
					</div>
				</SwiperSlide>

				<SwiperSlide>
					<div className="swiper-zoom-container">
						<img src={image8} alt="image4" />
					</div>
				</SwiperSlide>

				<SwiperSlide>
					<div className="swiper-zoom-container">
						<img src={image9} alt="image4" />
					</div>
				</SwiperSlide>

				<SwiperSlide>
					<div className="swiper-zoom-container">
						<img src={image10} alt="image4" />
					</div>
				</SwiperSlide>
			</Swiper>
		</animated.div>,
		document.getElementById("portal")
	)
}

export default FbasCarousel
